import ExamMemoryList from '@/components/ExamMemoryList/ExamMemoryList.vue'
export default {
    components: {
        ExamMemoryList
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
}